<script>
    import CatalogSort from "@tb/components/CatalogSort";
    import ListSkeleton from "@tb/components/ListSkeleton";

    export default {
        name: "CatalogList",
        components: {
            CatalogSort,
            ListSkeleton,
        },
        props: {
            loading: Boolean,
            currentSortName: String,
        },
        computed: {
            isPopular() {
                return this.currentSortName === "popular";
            },
        },
        methods: {
            sortGoods(name) {
                this.$emit("sort", name);
            },
        },
    };
</script>

<template>
    <div class="catalog-list">
        <catalog-sort @sort="sortGoods" :sort-name="currentSortName"></catalog-sort>

        <div v-if="loading" class="catalog-list__skeleton">
            <list-skeleton :isPopular="isPopular"></list-skeleton>
        </div>

        <div
            v-if="!loading"
            class="catalog-list__wrap"
            :class="{
                'catalog-list__wrap--popular': isPopular,
            }"
        >
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss">
    .catalog-list {
        position: relative;
    }

    .catalog-list__skeleton {
        //position: absolute;
        //width: 100%;
        //z-index: 1000;
        //opacity: 0.5;
    }

    .catalog-list__wrap {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px;
        min-height: 300px;
        transition: 0.25s ease-in-out opacity;

        @include bp($bp-desktop-sm-for-card) {
            margin: 0;
        }

        .goods-card {
            width: calc(100% / 2 - 24px);
            margin: 0 12px 24px;

            @include bp($bp-tablet-sm) {
                margin-bottom: 48px;
                width: calc(100% / 4 - 24px);
            }

            @include bp($bp-desktop-sm-for-card) {
                width: calc(100% / 4);
                margin: 0 0 24px;
            }
        }
    }

    .catalog-list__wrap--popular {
        .goods-card {
            &:nth-child(1) {
                width: calc(100% - 24px);

                svg {
                    display: flex;
                }

                span {
                    display: none;
                }

                .goods-card__options {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                @include bp($bp-tablet-sm) {
                    width: calc(100% / 2 - 24px);
                }

                @include bp($bp-desktop-sm-for-card) {
                    width: calc(100% / 2);
                }
            }

            &:nth-child(2) {
                width: calc(100% - 24px);

                svg {
                    display: flex;
                }

                span {
                    display: none;
                }

                .goods-card__options {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                @include bp($bp-tablet-sm) {
                    width: calc(100% / 2 - 24px);
                }

                @include bp($bp-desktop-sm-for-card) {
                    width: calc(100% / 2);
                }
            }

            &:nth-child(3) {
                width: calc(100% / 2 - 24px);

                @include bp($bp-tablet-sm) {
                    width: calc(100% / 3 - 24px);
                }

                @include bp($bp-desktop-sm-for-card) {
                    width: calc(100% / 3);
                }
            }

            &:nth-child(4) {
                width: calc(100% / 2 - 24px);

                @include bp($bp-tablet-sm) {
                    width: calc(100% / 3 - 24px);
                }

                @include bp($bp-desktop-sm-for-card) {
                    width: calc(100% / 3);
                }
            }

            &:nth-child(5) {
                width: calc(100% / 2 - 24px);

                @include bp($bp-tablet-sm) {
                    width: calc(100% / 3 - 24px);
                }

                @include bp($bp-desktop-sm-for-card) {
                    width: calc(100% / 3);
                }
            }
        }
    }
</style>
