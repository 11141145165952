<script>
    import TheSvgIcon from "@main/packages/TheSvgIcon";

    export default {
        name: "CatalogSort",
        components: {
            TheSvgIcon,
        },
        props: {
            sortName: String,
        },
        data() {
            return {
                currentSortName: this.sortName,
            };
        },
        watch: {
            sortName: {
                handler(val) {
                    this.currentSortName = val;
                },
            },
        },
        methods: {
            sort(name) {
                this.$emit("sort", name ? name : this.currentSortName);
            },
        },
    };
</script>

<template>
    <div class="b-sort">
        <div class="b-sort__desktop">
            <div class="b-sort__title">Сортировка:</div>
            <div class="b-sort__wrap">
                <button
                    class="b-sort__type outline"
                    :class="{ 'b-sort__type--active': currentSortName === 'popular' }"
                    @click="sort('popular')"
                >
                    По популярности
                </button>
                <button
                    class="b-sort__type outline"
                    :class="{ 'b-sort__type--active': currentSortName === 'price' }"
                    @click="sort('price')"
                >
                    Цене
                </button>
                <button
                    class="b-sort__type outline"
                    :class="{ 'b-sort__type--active': currentSortName === 'discount' }"
                    @click="sort('discount')"
                >
                    Скидке
                </button>
            </div>
        </div>

        <div class="b-sort__mobile">
            <div class="b-sort__selection">
                <select @change="sort('')" v-model="currentSortName" class="b-sort__select">
                    <option value="popular">По популярности</option>
                    <option value="price">Цене</option>
                    <option value="discount">Скидке</option>
                </select>
                <the-svg-icon
                    name="arrow_right"
                    is-common
                    :size-w="16"
                    :size-h="24"
                    class="b-sort__arrow"
                ></the-svg-icon>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .b-sort {
    }

    .b-sort__desktop {
        display: none;

        @include bp($bp-tablet-sm) {
            display: flex;
            padding: 12px 16px;
            margin: 0 -16px 24px;
            background-color: var(--neutral--light);
        }

        @include bp($bp-desktop-sm) {
            margin: 0 0 24px;
        }
    }

    .b-sort__mobile {
        margin-bottom: 16px;

        @include bp($bp-tablet-sm) {
            display: none;
        }
    }

    .b-sort__selection {
        position: relative;
        display: inline-block;
    }

    .b-sort__select {
        display: block;
        width: 185px;
        padding: 6px 12px;
        background: none;
        border: 1px solid var(--primary-border--light);
        border-radius: 3px;
        appearance: none;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: var(--secondary-text);
    }

    .b-sort__arrow {
        position: absolute;
        top: 8px;
        right: 10px;
        transform: rotate(90deg);
        pointer-events: none;
    }

    .b-sort__title {
        font-size: 15px;
        line-height: 24px;
        color: var(--secondary-text);
    }

    .b-sort__wrap {
        display: flex;
        margin-left: 12px;
    }

    .b-sort__type {
        font-size: 15px;
        line-height: 24px;
        color: var(--secondary-text);
        margin-right: 8px;
        cursor: pointer;
    }

    .b-sort__type--active {
        color: var(--primary);
    }
</style>
