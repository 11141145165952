<script>
    import { mapActions, mapGetters, mapState } from "vuex";
    import { apiUrl } from "@/utils";
    import { metaInfo } from "@/core/MetaInfo";
    import { sendAnalytics } from "@/core/SendAnalytics";
    import CatalogContainer from "@tb/components/CatalogContainer";
    import BannerCatalog from "@tb/components/CatalogBanner";
    import ProductCard from "@tb/components/ProductCard";
    import CatalogList from "@tb/components/CatalogList";

    export default {
        name: "CatalogCategory",
        components: {
            CatalogContainer,
            CatalogList,
            BannerCatalog,
            ProductCard,
        },
        data() {
            let section = this.$store.state.Catalog.categoryNames.filter(
                (section) => section.slug === this.$route.params.slug
            )[0];
            return {
                currentSortName: "popular",
                currentPage: section,
                typeCatalog: section.depthLevel === 2 ? "section" : "category",
            };
        },
        computed: {
            ...mapState("Catalog", ["catalog", "loading", "pageTotal"]),
            ...mapGetters("Catalog", ["mainSection"]),
            title() {
                return this.typeCatalog === "section"
                    ? this.currentPage.name
                    : "Каталог товаров";
            },
        },
        watch: {
            $route() {
                let section = this.$store.state.Catalog.categoryNames.filter(
                    (section) => section.slug === this.$route.params.slug
                )[0];

                this.currentPage = section;
                this.typeCatalog = section.depthLevel === 2 ? "section" : "category";
                this.update();
            },
            catalog: {
                handler(updatedCatalog) {
                    if (
                        process.env.VUE_APP_ENV === "client" &&
                        Object.keys(updatedCatalog).length
                    ) {
                        sendAnalytics.send(sendAnalytics.events.productList, updatedCatalog, {
                            mainSection: this.mainSection,
                        });
                    }
                },
                immediate: true,
            },
        },
        serverPrefetch() {
            return this.getCatalogForId({ id: this.currentPage.id });
        },
        destroyed() {
            this.clearCatalog();
        },
        async mounted() {
            if (!this.catalog.length) {
                await this.getCatalogForId({ id: this.currentPage.id });
            }
        },
        methods: {
            ...mapActions("Catalog", ["getCatalogForId", "clearCatalog"]),
            apiUrl,
            sortGoods(name) {
                this.currentSortName = name;
                this.getCatalogForId({ id: this.currentPage.id, sort: name });
            },
            async update() {
                this.currentSortName = "popular";
                await this.getCatalogForId({ id: this.currentPage.id });
            },
        },
        metaInfo() {
            return metaInfo.getProductMeta({
                title: this.currentPage.title,
                description: this.currentPage.text,
                slug: `c/${this.currentPage.slug}`,
            });
        },
    };
</script>

<template>
    <div class="l-container">
        <catalog-container>
            <template #title>
                {{ title }}
            </template>

            <template v-if="typeCatalog === 'section'" #description>
                <div v-html="currentPage.text"></div>
            </template>

            <template #body>
                <div></div>
                <banner-catalog
                    v-if="typeCatalog === 'section'"
                    :title="currentPage.name"
                    :description="currentPage.text"
                    :src="apiUrl(currentPage.detailPicture)"
                ></banner-catalog>

                <div v-show="typeCatalog === 'category'" class="catalog-list__header">
                    <div class="catalog-list__title">
                        {{ currentPage.name }}

                        <div class="catalog-list__number">
                            {{ pageTotal }}
                            {{ pageTotal | formatCase(["товар", "товара", "товаров"]) }}
                        </div>
                    </div>
                </div>

                <catalog-list
                    :loading="loading"
                    @sort="sortGoods"
                    :currentSortName="currentSortName"
                >
                    <product-card
                        :goods="product"
                        :position="i + 1"
                        v-for="(product, i) in catalog"
                        :key="product.id"
                    ></product-card>
                </catalog-list>
            </template>
        </catalog-container>
    </div>
</template>

<style lang="scss">
    .b-catalog-section {
        display: flex;
    }

    .catalog-list__header {
        margin-bottom: 24px;
    }

    .catalog-list__title {
        font-weight: 600;
        font-size: 30px;
        line-height: 34px;

        @include bp($bp-desktop-sm) {
            font-size: 40px;
            line-height: 44px;
        }
    }

    .catalog-list__number {
        display: inline;
        font-size: 13px;
        line-height: 19px;

        color: var(--secondary-text);
        white-space: nowrap;

        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 27px;
        }
    }
</style>
