<script>
    import { ContentLoader } from "vue-content-loader";
    export default {
        name: "ListSkeleton",
        components: {
            ContentLoader,
        },
        props: {
            isPopular: Boolean,
        },
    };
</script>

<template>
    <div class="skltn">
        <content-loader
            class="skltn--dsktp"
            :width="100"
            :height="200"
            :speed="1.5"
            primaryColor="#EBEFF2"
            :secondaryOpacity="0.5"
        >
            <template v-if="isPopular">
                <rect x="0" y="0" rx="0.5" ry="0.5" width="47.5" height="45" />
                <rect x="50" y="0" rx="0.5" ry="0.5" width="47.5" height="45" />

                <rect x="0" y="46" rx="0.5" ry="0.5" width="24" height="2" />
                <rect x="50" y="46" rx="0.5" ry="0.5" width="24" height="2" />

                <rect x="0" y="49.2" rx="0.5" ry="0.5" width="12" height="3" />
                <rect x="50" y="49.2" rx="0.5" ry="0.5" width="12" height="3" />

                <!--divider-->

                <rect x="0" y="59.5" rx="0.5" ry="0.5" width="31" height="28" />
                <rect x="33.5" y="59.5" rx="0.5" ry="0.5" width="31" height="28" />
                <rect x="66.5" y="59.5" rx="0.5" ry="0.5" width="31" height="28" />

                <rect x="0" y="88.5" rx="0.5" ry="0.5" width="24" height="2" />
                <rect x="33.5" y="88.5" rx="0.5" ry="0.5" width="24" height="2" />
                <rect x="66.5" y="88.5" rx="0.5" ry="0.5" width="24" height="2" />

                <rect x="0" y="91.7" rx="0.5" ry="0.5" width="12" height="3" />
                <rect x="33.5" y="91.7" rx="0.5" ry="0.5" width="12" height="3" />
                <rect x="66.5" y="91.7" rx="0.5" ry="0.5" width="12" height="3" />

                <!--divider-->

                <rect x="0" y="102.5" rx="0.5" ry="0.5" width="23" height="19.5" />
                <rect x="25" y="102.5" rx="0.5" ry="0.5" width="23" height="19.5" />
                <rect x="50" y="102.5" rx="0.5" ry="0.5" width="23" height="19.5" />
                <rect x="75" y="102.5" rx="0.5" ry="0.5" width="23" height="19.5" />

                <rect x="0" y="123" rx="0.5" ry="0.5" width="19" height="2" />
                <rect x="25" y="123" rx="0.5" ry="0.5" width="19" height="2" />
                <rect x="50" y="123" rx="0.5" ry="0.5" width="19" height="2" />
                <rect x="75" y="123" rx="0.5" ry="0.5" width="19" height="2" />

                <rect x="0" y="126.2" rx="0.5" ry="0.5" width="10" height="3" />
                <rect x="25" y="126.2" rx="0.5" ry="0.5" width="10" height="3" />
                <rect x="50" y="126.2" rx="0.5" ry="0.5" width="10" height="3" />
                <rect x="75" y="126.2" rx="0.5" ry="0.5" width="10" height="3" />
            </template>

            <template v-else>
                <rect x="0" y="0" rx="0.5" ry="0.5" width="23" height="19.5" />
                <rect x="25" y="0" rx="0.5" ry="0.5" width="23" height="19.5" />
                <rect x="50" y="0" rx="0.5" ry="0.5" width="23" height="19.5" />
                <rect x="75" y="0" rx="0.5" ry="0.5" width="23" height="19.5" />

                <rect x="0" y="20.5" rx="0.5" ry="0.5" width="19" height="2" />
                <rect x="25" y="20.5" rx="0.5" ry="0.5" width="19" height="2" />
                <rect x="50" y="20.5" rx="0.5" ry="0.5" width="19" height="2" />
                <rect x="75" y="20.5" rx="0.5" ry="0.5" width="19" height="2" />

                <rect x="0" y="23.7" rx="0.5" ry="0.5" width="10" height="3" />
                <rect x="25" y="23.7" rx="0.5" ry="0.5" width="10" height="3" />
                <rect x="50" y="23.7" rx="0.5" ry="0.5" width="10" height="3" />
                <rect x="75" y="23.7" rx="0.5" ry="0.5" width="10" height="3" />

                <!--divider-->

                <rect x="0" y="34.5" rx="0.5" ry="0.5" width="23" height="19.5" />
                <rect x="25" y="34.5" rx="0.5" ry="0.5" width="23" height="19.5" />
                <rect x="50" y="34.5" rx="0.5" ry="0.5" width="23" height="19.5" />
                <rect x="75" y="34.5" rx="0.5" ry="0.5" width="23" height="19.5" />

                <rect x="0" y="55" rx="0.5" ry="0.5" width="19" height="2" />
                <rect x="25" y="55" rx="0.5" ry="0.5" width="19" height="2" />
                <rect x="50" y="55" rx="0.5" ry="0.5" width="19" height="2" />
                <rect x="75" y="55" rx="0.5" ry="0.5" width="19" height="2" />

                <rect x="0" y="58.2" rx="0.5" ry="0.5" width="10" height="3" />
                <rect x="25" y="58.2" rx="0.5" ry="0.5" width="10" height="3" />
                <rect x="50" y="58.2" rx="0.5" ry="0.5" width="10" height="3" />
                <rect x="75" y="58.2" rx="0.5" ry="0.5" width="10" height="3" />

                <!--divider-->

                <rect x="0" y="69" rx="0.5" ry="0.5" width="23" height="19.5" />
                <rect x="25" y="69" rx="0.5" ry="0.5" width="23" height="19.5" />
                <rect x="50" y="69" rx="0.5" ry="0.5" width="23" height="19.5" />
                <rect x="75" y="69" rx="0.5" ry="0.5" width="23" height="19.5" />

                <rect x="0" y="89.5" rx="0.5" ry="0.5" width="19" height="2" />
                <rect x="25" y="89.5" rx="0.5" ry="0.5" width="19" height="2" />
                <rect x="50" y="89.5" rx="0.5" ry="0.5" width="19" height="2" />
                <rect x="75" y="89.5" rx="0.5" ry="0.5" width="19" height="2" />

                <rect x="0" y="92.7" rx="0.5" ry="0.5" width="10" height="3" />
                <rect x="25" y="92.7" rx="0.5" ry="0.5" width="10" height="3" />
                <rect x="50" y="92.7" rx="0.5" ry="0.5" width="10" height="3" />
                <rect x="75" y="92.7" rx="0.5" ry="0.5" width="10" height="3" />
            </template>
        </content-loader>

        <content-loader
            class="skltn--mbl"
            :width="100"
            :height="400"
            :speed="1.5"
            primaryColor="#EBEFF2"
            :secondaryOpacity="0.5"
        >
            <template v-if="isPopular">
                <rect x="0" y="0" rx="0.5" ry="0.5" width="100" height="90" />
                <rect x="0" y="94" rx="0.5" ry="0.5" width="88" height="6" />
                <rect x="0" y="104" rx="0.5" ry="0.5" width="35" height="9" />

                <!--divider-->

                <rect x="0" y="130" rx="0.5" ry="0.5" width="100" height="90" />
                <rect x="0" y="224" rx="0.5" ry="0.5" width="88" height="6" />
                <rect x="0" y="234" rx="0.5" ry="0.5" width="35" height="9" />

                <!--divider-->

                <rect x="0" y="260" rx="0.5" ry="0.5" width="46" height="37" />
                <rect x="54" y="260" rx="0.5" ry="0.5" width="46" height="37" />

                <rect x="0" y="300" rx="0.5" ry="0.5" width="40" height="6" />
                <rect x="54" y="300" rx="0.5" ry="0.5" width="40" height="6" />

                <rect x="0" y="310" rx="0.5" ry="0.5" width="25" height="9" />
                <rect x="54" y="310" rx="0.5" ry="0.5" width="25" height="9" />
            </template>

            <template v-else>
                <rect x="0" y="0" rx="0.5" ry="0.5" width="46" height="37" />
                <rect x="54" y="0" rx="0.5" ry="0.5" width="46" height="37" />

                <rect x="0" y="40" rx="0.5" ry="0.5" width="40" height="6" />
                <rect x="54" y="40" rx="0.5" ry="0.5" width="40" height="6" />

                <rect x="0" y="50" rx="0.5" ry="0.5" width="25" height="9" />
                <rect x="54" y="50" rx="0.5" ry="0.5" width="25" height="9" />

                <!--divider-->

                <rect x="0" y="82" rx="0.5" ry="0.5" width="46" height="37" />
                <rect x="54" y="82" rx="0.5" ry="0.5" width="46" height="37" />

                <rect x="0" y="122" rx="0.5" ry="0.5" width="40" height="6" />
                <rect x="54" y="122" rx="0.5" ry="0.5" width="40" height="6" />

                <rect x="0" y="132" rx="0.5" ry="0.5" width="25" height="9" />
                <rect x="54" y="132" rx="0.5" ry="0.5" width="25" height="9" />

                <!--divider-->

                <rect x="0" y="163" rx="0.5" ry="0.5" width="46" height="37" />
                <rect x="54" y="163" rx="0.5" ry="0.5" width="46" height="37" />

                <rect x="0" y="203" rx="0.5" ry="0.5" width="40" height="6" />
                <rect x="54" y="203" rx="0.5" ry="0.5" width="40" height="6" />

                <rect x="0" y="213" rx="0.5" ry="0.5" width="25" height="9" />
                <rect x="54" y="213" rx="0.5" ry="0.5" width="25" height="9" />
            </template>
        </content-loader>
    </div>
</template>

<style scoped lang="scss">
    .skltn--dsktp {
        display: none;
        @include bp($bp-tablet-sm) {
            display: block;
        }
    }

    .skltn--mbl {
        @include bp($bp-tablet-sm) {
            display: none;
        }
    }
</style>
